export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.subMenu()
        NavbarComponent.langs_switcher()
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 100 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                navbar.removeClass('navbar--ontop')
                $('body').removeClass('scroll--ontop')
                navbar.addClass('navbar--onscroll')
                if (full === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else {
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
                $('body').addClass('scroll--ontop')
                if (full === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogo() {
        const body = $('body')
        if (window.matchMedia('(max-width: 650px)').matches) {
            if (body.hasClass('menu-open')) {
                NavbarComponent.changeLogoColor('classic')
            } else {
                if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        }
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        const body = $('body')
        const open = '[data-trigger="wpiris-menu-toggle"]'
        const closed = '[data-trigger="wpiris-menu-close"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        //Open/Close menu
        $(open).on('click', () => {
            body.addClass('menu-open')
            //Change logo on mobile
            NavbarComponent.changeLogo()
        })
        $(closed).on('click', () => {
            body.removeClass('menu-open')
            body.removeClass('submenu-open')
            $(submenu).removeClass('submenu-open')

            //Change logo on mobile
            NavbarComponent.changeLogo()
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
            body.removeClass('submenu-open')
        })
    }

    static subMenu() {
        const menuActive = '[data-trigger="wpiris-menu-active"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        if (window.matchMedia('(min-width:1000px)').matches) {
            $('.menu__item').first().addClass('menu__item--active')
        }

        $(menuActive).on('click', function (e) {
            if (window.matchMedia('(min-width:1000px)').matches) {
                // second click (already open)
                if ($(this).parents('.menu__item').hasClass('menu__item--active')) {
                    return true
                }
            }

            // first click (open submenu)
            $('.menu__item').removeClass('menu__item--active')
            $(this).parents('.menu__item').addClass('menu__item--active')
            if (window.matchMedia('(max-width:1000px)').matches) {
                e.preventDefault()
                $('.menu__item--active .menu__item__has-children--mobile')
                    .next(submenu)
                    .toggleClass('submenu-open')

                if ($('.menu__item__submenu').hasClass('submenu-open')) {
                    $('body').addClass('submenu-open')
                }
            }

            return false
        })
    }

    static langs_switcher() {
        const switcherLang = $('[data-trigger="switcher-lang"]')
        const listLang = $('[data-element="list-lang"]')

        // Hide submenu initially
        listLang.hide()

        // Open switcher list
        switcherLang.on('click', (e) => {
            e.stopPropagation() // Empêche la propagation du clic
            listLang.toggleClass('list-open')
            if (listLang.hasClass('list-open')) {
                listLang.fadeIn(250)
            } else {
                listLang.fadeOut(250)
            }
        })

        // Hide list lang on outside click
        $(document).on('click', (e) => {
            if (
                !switcherLang.is(e.target) &&
                !listLang.is(e.target) &&
                listLang.has(e.target).length === 0
            ) {
                listLang.removeClass('list-open').fadeOut(250)
            }
        })
    }
}
